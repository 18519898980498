/* eslint-disable react/jsx-props-no-spreading */
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { datadogNextjs } from "datadog-config";
import type { AppProps } from "next/app";
import dynamic from "next/dynamic";
import Head from "next/head";
import Router from "next/router";
import { Suspense, useEffect } from "react";
import { CookiesProvider } from "react-cookie";
import { RecoilRoot } from "recoil";

import "../styles/global.css";

import appPacakge from "../package.json";

import { GA_ID } from "@/contents/landingPage";

const { initDatadog } = datadogNextjs(
  process.env.NEXT_PUBLIC_MODE === "development"
    ? {
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        packageVersion: appPacakge.version
      }
    : {
        sessionSampleRate: 3,
        sessionReplaySampleRate: 20,
        packageVersion: appPacakge.version
      }
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
      useErrorBoundary: true,
      staleTime: 1000
    },
    mutations: {
      useErrorBoundary: true
    }
  }
});

const App = ({ Component, pageProps }: AppProps) => {
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_MODE === "production") {
      const handleRouteChangeComplete = () => {
        if (typeof window === "object") {
          const { pathname } = window.location;

          window.gtag("config", `${GA_ID}`, {
            page_path: pathname
          });
        }
      };

      Router.events.on("routeChangeComplete", handleRouteChangeComplete);

      return () => {
        Router.events.off("routeChangeComplete", handleRouteChangeComplete);
      };
    }
  }, []);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, user-scalable=no"
        />
      </Head>
      <RecoilRoot>
        <Suspense fallback={<></>}>
          <CookiesProvider>
            <QueryClientProvider client={queryClient}>
              <Component {...pageProps} />
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </CookiesProvider>
        </Suspense>
      </RecoilRoot>
    </>
  );
};

initDatadog();

export default dynamic(() => Promise.resolve(App), {
  ssr: false
});
